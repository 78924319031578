import { createRouter, createWebHistory } from 'vue-router'
import { nextTick } from 'vue'


function load(componentName) {
  return () => import(`@/components/${componentName}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: load('HomeView')
  },
  {
    path: '/projects',
    name: 'ProjectsSection',
    component: load('ProjectsSection'),
    meta: { title: 'Projects' }
  },
  {
    path: '/project/:url',
    name: 'ProjectView',
    component: load('ProjectView'),
    meta: { title: 'Project', scrollToTop: true }
  },
  {
    path: '/about',
    name: 'AboutView',
    component: load('AboutView'),
    meta: { title: 'About Anita' }
  },
  {
    path: '/contact',
    name: 'ContactView',
    component: load('ContactView'),
    meta: { title: 'Contact Anita' }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: load('NotFound'),
    meta: { title: '404' }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const scrollContainer = document.querySelector('#wallpaper')
        if (to.name === 'ProjectView') {
          if (scrollContainer) {
            scrollContainer.scrollTop = 0
          }
          resolve({ top: 0, behavior: 'smooth' })
        } else if (savedPosition) {
          resolve(savedPosition)
        } else if (to.hash) {
          resolve({ el: to.hash, behavior: 'smooth' })
        } else if (to.matched.some(record => record.meta.scrollToTop)) {
          if (scrollContainer) {
            scrollContainer.scrollTop = 0
          }
          resolve({ top: 0, behavior: 'smooth' })
        } else {
          if (scrollContainer) {
            scrollContainer.scrollTop = 0
          }
          resolve({ top: 0, behavior: 'smooth' }) // Default to scrolling to top
        }
      }, 300) // Increased delay to 300ms
    })
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Anita Kucharczyk'
  next()
})

router.afterEach(() => {
  nextTick(() => {
    const currentComponent = router.currentRoute.value.matched[0].instances.default
    if (currentComponent && currentComponent.initParallax) {
      currentComponent.initParallax()
    }
  })
})


export default router
import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import VueGtag from 'vue-gtag'
import VueScrollTo from 'vue-scrollto'

// Import global styles
import "@/styles/global.scss"


const app = createApp(App)

// VueScrollTo configuration
app.use(VueScrollTo, {
  container: "#wallpaper",
  duration: 1000,
  easing: "ease",
})

// VueGtag configuration
app.use(VueGtag, {
  config: { id: "UA-1234567-1" }
}, router)

app.use(router)
app.mount('#app')
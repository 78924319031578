<template>
  <WallpaperComp />
</template>

<script>

import WallpaperComp from './components/WallpaperComp.vue'

export default {
  name: 'app',
  components: {
    WallpaperComp
  },
  methods: {
    beforeEnter () {
      this.$root.$emit('scrollBeforeEnter');
    }
  }
}
</script>
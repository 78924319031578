<template>
  <div id="root">
    <div 
      id="initials"
      :class="{ hide: menu[2], show: scrolled && menu[2], delayshow: !menu[2] }"
    >
      <router-link 
        :href="menu[0]"
        :to="'/'"
        class="link"
        v-scroll-to="menu[1]"
      >
        <div class="mark">A<br />K.</div>
        <div 
          class="arrow-up"
          v-show="$route.name=='HomeView'"
        ></div>
        <div 
          class="arrow-back"
          v-show="$route.name!=='HomeView'"
        ></div>
      </router-link>
    </div>
    <div 
      id="scroll-to-top"
      class="hide"
      :class="{ show: scrolled && !menu[2] }"
    >
      <a 
        href=""
        v-scroll-to="'#root'"
      ></a>
    </div>
    <div id="hue-shift">
      <div class="bg"></div>
    </div>
    <div id="wallpaper" ref="wallpaper" @scroll="handleScroll">
      <div id="window-container">
        
        <div 
          v-for="(window, index) in visibleWindows" 
          :key="window.id"
          class="window"
          :class="[
            window.style,
            { 'initial-window': index === 0 && !window.hasAppeared }
          ]"
          :style="{
            transform: `translateY(${window.position}px) scale(${window.scale})`,
            left: window.left,
          }"
        ></div>

      </div>
      
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" class="view" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrolled: false,
      visibleWindows: [],
      windowStyles: [
        'three', 'single-wide', 'single-square', 'double-vert', 'single-tall-cold',
        'double-horz', 'single-med-wide', 'single-wide-cold', 'single-med-tall',
        'single-square-warm', 'double-horz-wide'
      ],
      scrollPosition: 0,
      containerHeight: 0,
      lastScrollPosition: 0,
      nextWindowId: 0,
      isInitialWindowCreated: false,
      initialWindowVisible: false,
      initialWindow: null,
      initialWindowOpacity: 0,
      isInitialWindowFaded: false
    }
  },
  mounted() {
  this.$nextTick(() => {
    this.containerHeight = window.innerHeight;
    this.addInitialWindow();
    window.addEventListener('resize', this.handleResize);
    
    // Trigger fade-in for initial window
    setTimeout(() => {
      this.isInitialWindowFaded = true;
    }, 50);
  });
},

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    addInitialWindow() {
    const initialWindow = this.createCenteredWindow();
    this.visibleWindows.push(initialWindow);
  },


  createCenteredWindow() {
    return {
      id: this.nextWindowId++,
      style: this.windowStyles[Math.floor(Math.random() * this.windowStyles.length)],
      scale: 1.2,
      position: this.containerHeight / 2 - (this.containerHeight * 0.4),
      left: '20%',
      hasAppeared: false
    };
  },
  createWindow(startPosition) {
  return {
    id: this.nextWindowId++,
    style: this.windowStyles[Math.floor(Math.random() * this.windowStyles.length)],
    position: startPosition,
    left: this.randomPosition(),
    scale: this.randomScale(),
    hasAppeared: true
  };
},

    randomPosition() {
      const positions = ['-20%', '-17%', '-15%', '15%', '25%', '35%', '45%', '55%', '80%'];
      return positions[Math.floor(Math.random() * positions.length)];
    },
    randomScale() {
      return 0.5 + Math.random() * 0.5;
    },
    handleScroll(event) {
      requestAnimationFrame(() => {
        const newScrollPosition = event.target.scrollTop;
        const delta = newScrollPosition - this.lastScrollPosition;
        this.scrollPosition = newScrollPosition;
        this.scrolled = this.scrollPosition >= window.innerHeight - 20;
        this.updateWindows(delta);
        this.lastScrollPosition = newScrollPosition;
        //console.log('Scroll event fired. Delta:', delta);
      });
    },

    updateWindows(delta) {
      const parallaxFactor = 1.2; // Adjust this value to control parallax intensity

      // Move existing windows
      this.visibleWindows.forEach(window => {
        window.position -= delta * parallaxFactor;
      });

      //console.log('Windows updated. Current positions:', this.visibleWindows.map(w => w.position));

      // Remove windows that have moved completely out of view
      this.visibleWindows = this.visibleWindows.filter(window => 
        window.position > -this.containerHeight && window.position < this.containerHeight
      );

      // Add new window if needed
      const threshold = this.containerHeight * 0.3;
      if (delta > 0 && !this.visibleWindows.some(w => w.position > threshold)) {
        this.visibleWindows.push(this.createWindow(this.containerHeight));
        //console.log('New window added (scrolling down)');
      } else if (delta < 0 && !this.visibleWindows.some(w => w.position < -threshold)) {
        this.visibleWindows.unshift(this.createWindow(-this.containerHeight));
        //console.log('New window added (scrolling up)');
      }

      // Limit the number of windows
      while (this.visibleWindows.length > 3) {
        if (delta > 0) {
          this.visibleWindows.shift();
        } else {
          this.visibleWindows.pop();
        }
        //console.log('Excess window removed. Current count:', this.visibleWindows.length);
      }
    },
    handleResize() {
      this.containerHeight = window.innerHeight;
      this.visibleWindows.forEach(window => {
        window.position = Math.max(-this.containerHeight, Math.min(this.containerHeight, window.position));
      });
    },
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.visibleWindows = [];
        this.lastScrollPosition = 0;
        this.scrollPosition = 0;
        this.addInitialWindow();
      });
    }
  },

  

  
  computed: {
    menu() {
      let goTo, scrollTo, notHomeView;
      if (this.$route.name !== 'HomeView') {
        goTo = '/';
        scrollTo = '';
      } else {
        goTo = '#';
        scrollTo = '#root';
        notHomeView = true;
      }
      return [goTo, scrollTo, notHomeView];
    }
  }
}
</script>

  <style lang="scss" scoped>
    #root {
      // background: linear-gradient(90deg, rgba(207,197,197,1) 49%, rgba(159,139,139,1) 51%);
      // overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100vw; // Ensure full viewport width
      overflow-x: hidden; // Prevent horizontal scrolling
      //transform-style: preserve-3d;
      //z-index: -5;

      #initials {
        //left: 20px;
        top: $margin-top;
        position: fixed;
        width: 7.5%;
        z-index: 500;
        // opacity: 0;
        //transition: 1s all;
        
        .link {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column; 
          //transition: 0.5s all;
          cursor: pointer;
          padding-bottom: 150px;

          .mark {
            //width: 50px;
            //height: 120px;
            font-size: 60px;
            word-wrap: break-word;
            //margin: 0 auto;
            opacity: 0;
            animation: $anim-fade-in-1; 
          }

          .arrow-up, .arrow-back {
            position: absolute;
            margin-top: 150px;
            transition: all 0.5s;
            display: inherit !important;
            transform: rotate(0deg) scale(1);
          }

          .arrow-up {
            width: 60px;
            height: 68px;
            background: url('../assets/svg/arrow-vertical.svg') repeat-y;
            background-position: 50% 50%;
          }
          .arrow-back {
            width: 70px;
            height: 70px;
            background: url('../assets/svg/arrow-horizontal.svg') repeat-x;
            background-size: contain;
            background-position: 50% 50%;
          }
          .arrow-up[style*="display: none;"], .arrow-back[style*="display: none;"] {
            opacity: 0;
            pointer-events: none;
            user-select: none;
          }
          .arrow-up[style*="display: none;"] {
            transform: rotate(-90deg);
          }
          .arrow-back[style*="display: none;"] {
            transform: rotate(90deg) scale(0.5);
          }
        }
        
        .link:hover {
          transform: scale(1);
          transition: 0.3s all;
          color: $dark;

          .arrow-up {
            background-position-y: -70px;
          }
          .arrow-back {
            // transform: translateX(-10px) scale(0.6);
            background-position-x: -70px;
          }
        }
        .link:active {
          transform: scale(0.8);
          transition: 0.2s all;
        }

      }

      #scroll-to-top {
        position: fixed;
        right: 0;
        bottom: 130px;
        width: 8%;
        z-index: 500;
        //opacity: 0;

        a {
          position: absolute;
          width: 60px;
          height: 60px;
          background: url('../assets/svg/arrow-vertical.svg') repeat-y;
          background-position: 50% 50%;
          background-size: contain;
        }

        a:hover {
          background-position-y: -60px;
          transition: 0.5s all;
        }
      }

      .hide-show {
        opacity: 0;
      }

      .hide {
        opacity: 0;
        transform: translateX(-20%);
        pointer-events: none;
      }
      .show {
        opacity: 1;
        transform: translateX(0%);
        transition: 1s all;
        pointer-events: auto;
      }

      .delayshow {
        opacity: 1;
        transition: 1s all;
        transition-delay: 0.3s;
      }
        
      #window-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        pointer-events: none;
        overflow: hidden;
        z-index: 1000;
        mix-blend-mode: overlay;

        .initial-window {
      opacity: 0;
      animation: fadeIn 1s ease-out 0.1s forwards;
    }

      }

      .window {
        position: absolute;
        width: 60%;
        height: 80vh;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        will-change: transform, opacity;
        transition: opacity 1s ease-out;
      }

      .fade-in {
        opacity: 1;
      }

      @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
      }

      #wallpaper {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;

        &::after {
          content: '';
          display: block;
          width: 100%;
          max-width: $breakpoint-max; // Use your desired max-width
          flex: 1;
        }

        .view {
          //width: 100%;
          max-width: $breakpoint-max; // Use your desired max-width
        }
      }

      #hue-shift {
          position: fixed;
          width: 100%;
          height: 100%;
          // background: url('../assets/img/BG.png') repeat-y 100%;
          // background-position: center center;
          // //width: 100%;
          // //overflow-y: auto;
          // //filter: hue-rotate(-180deg);
          animation: $anim-hue-rot;
          z-index: -100;

          .bg {
            position: relative;
            width: 100%;
            height: 100%;
            background: 
              linear-gradient(
                to right,
              hsl(0, 8.41%, 79.02%) 0%,
              hsl(0, 8.43%, 78.7%) 16.1%,
              hsl(0, 8.46%, 77.8%) 28.2%,
              hsl(0, 8.52%, 76.41%) 36.8%,
              hsl(0, 8.58%, 74.62%) 42.6%,
              hsl(0, 8.64%, 72.51%) 46.1%,
              hsl(0, 8.7%, 70.19%) 48.2%,
              hsl(0, 8.75%, 67.72%) 49.2%,
              hsl(0, 8.79%, 65.22%) 50%,
              hsl(0, 8.83%, 62.76%) 51.1%,
              hsl(0, 8.86%, 60.43%) 53.2%,
              hsl(0, 8.89%, 58.32%) 56.8%,
              hsl(0, 8.91%, 56.53%) 62.7%,
              hsl(0, 8.92%, 55.14%) 71.5%,
              hsl(0, 8.93%, 54.24%) 83.7%,
              hsl(0, 8.94%, 53.92%) 100%
              );
            z-index: -101;
          }
        }
    }
    @media screen and (max-width: $breakpoint-xxl) {
      #root {
        #initials {
          width: 7.5%;
          .link {
            padding-bottom: 90px;
            .mark {
              font-size: 3.75vw;
            }
            .arrow-up {
              width: 3.75vw;
              height: 3.75vw;
              background-size: contain;
              margin-top: 9vw;
            }
            .arrow-back {
              width: 4.5vw;
              height: 4.5vw;
              margin-top: 9vw;
            }
          }
          .link:hover {
            .arrow-up {
              background-position-y: -3.75vw;
            }
            .arrow-back {
              background-position-x: -4.5vw;
            }
          }
        }
        #scroll-to-top {
          bottom: 7vw; 
          a {
            width: 3.75vw;
            height: 3.75vw;
            //margin-top: 9vw;
          }
          a:hover {
            background-position-y: -3.75vw;
          }
        }
      }
    }
    @media screen and (max-width: $breakpoint-lg) {
      #root {
        #initials {
          width: 10%;
          top: $padding-nav-mobile;

          .link {
            padding-bottom: 80px;

            .mark {
              font-size: 5vw;
            }
            .arrow-up {
              width: 5vw;
              height: 5vw;
              margin-top: 12vw;
            }
            .arrow-back {
              width: 7vw;
              height: 7vw;
              margin-top: 12vw;
            }
          }
          .link:hover {
            .arrow-up {
              background-position-y: -5vw;
            }
            .arrow-back {
              background-position-x: -7vw;
            }
          }
        }
        #scroll-to-top {
          a {
            width: 5vw;
            height: 5vw;
            //margin-top: 12vw;
          }
          a:hover {
            background-position-y: -5vw;
          }
        }
      }
    }
    @media screen and (max-width: $breakpoint-md) {
      #root {
        #wallpaper {
          display: block;
        }

        #initials {
          width: 10%;
          top: auto;
          bottom: 50%;

          .link {
            padding-bottom: 0px; 
            
            .mark {
              font-size: 8vw;
            }
            .arrow-up {
              width: 8vw;
              height: 8vw;
              margin-top: 18vw;
            }
            .arrow-back {
              width: 8vw;
              height: 8vw;
              margin-top: 18vw;
            }
          }
          .link:hover {
            .arrow-up {
              background-position-y: -8vw;
            }
            .arrow-back {
              background-position-x: -8vw;
            }
          }
        }
        #scroll-to-top {
          top: auto;
          bottom: 60px;
          right: 4px;
          a {
            width: 8vw;
            height: 8vw;
            margin-top: 0;
          }
          a:hover {
            background-position-y: -8vw;
          }
        }
      }
    }
    @media screen and (max-width: $breakpoint-xs) {
    }
  </style>
